import { render, staticRenderFns } from "./Veiculos.vue?vue&type=template&id=08445d31&"
import script from "./Veiculos.js?vue&type=script&lang=js&"
export * from "./Veiculos.js?vue&type=script&lang=js&"
import style0 from "./Veiculos.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QRange from 'quasar/src/components/range/QRange.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QField from 'quasar/src/components/field/QField.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QList from 'quasar/src/components/item/QList.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QScrollObserver from 'quasar/src/components/scroll-observer/QScrollObserver.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QColor from 'quasar/src/components/color/QColor.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QScrollArea,QIcon,QBtn,QChip,QImg,QSelect,QItem,QItemSection,QCheckbox,QRange,QBtnToggle,QField,QInput,QList,QToggle,QScrollObserver,QSkeleton,QPagination,QSpinner,QBtnGroup,QExpansionItem,QColor});qInstall(component, 'directives', {Ripple});
